<template>
  <b-card v-if="data">

    <!-- the title at the top of the circle chart area -->
    <b-row class="text-center">
      <b-card-title class="mx-auto">
        {{ title }}
      </b-card-title>
    </b-row>
    
    <b-row class="text-center" v-if="message != undefined && $route.name == 'questionnaire-submissions' && completedOn[0] != undefined">
      <b-card-sub-title class="mx-auto mb-2">
        Last Completed on {{ completedOn[0].created_at }}
      </b-card-sub-title>
    </b-row>
    <b-row class="text-center" v-if="message != undefined && $route.name == 'questionnaire-details'">
      <b-card-sub-title class="mx-auto mb-2">
        Completed on {{ message.completedOnDate }}
      </b-card-sub-title>
    </b-row>
    <b-row v-else>
      <b-card-sub-title class="mx-auto mb-2">
        <what-does-this-mean :type="type" :subcategory="subcategory" />
      </b-card-sub-title>
    </b-row>

    <div class="justify-content-center mt-3 chart-row">
      <div v-if="data.series.length > 0">
        <vue-apex-charts
          ref="circleChart"
          height="200"
          :options="circleChart.chartOptions"
          :series="data.series"
        />
      </div>
      <div v-else class="d-flex justify-content-center align-items-center">
        <p class="mb-0 py-3 text-center">No mental health check results data was found for the selected period</p>
      </div>

      <div class="text-center" v-if="$route.name == 'questionnaire-details'">
        <b-card-sub-title class="mx-auto mt-2" style="z-index: 10;">
          <what-does-this-mean type="details-circle-chart" :message="message" :professional="professional" :subcategory="subcategory" />
        </b-card-sub-title>
      </div>

      <div v-if="data.series.length > 0">
        <div class="text-center" v-if="$route.name === 'my-hub'">
          <b-card-sub-title class="mx-auto mt-2" style="z-index: 10;">
            <what-does-this-mean type="my-hub" :subcategory="subcategory" :message="message" :circleChartData="data" :barChartData="bar" :chartColor="chartColor" :barCategories="barCategories" :barColors="barColors"/>
          </b-card-sub-title>
        </div>

        <div class="text-center" v-if="$route.name === 'questionnaire-submissions'">
          <b-card-sub-title class="mx-auto mt-2" style="z-index: 10;">
            <what-does-this-mean type="questionnaire-submissions" :subcategory="subcategory" :message="message" :qName="qName" :circleChartData="data" :barChartData="bar" :barCategories="barCategories" :chartColor="chartColor" :barColors="barColors" />
          </b-card-sub-title>
        </div>
      </div>
    </div>
    
    <div v-if="data.series.length > 0 && (questionnaireCategory == 'My mental health and my life situation(s)' || questionnaireCategory == 'My mental health and my job/career' || questionnaireCategory == 'My mental wellbeing')">
      <div class="mt-2">
        <b-card-title class="text-center">Contributing factors</b-card-title>
        <b-card-sub-title class="text-center mb-2">
          <what-does-this-mean type="home-circle-chart-bottom" />
        </b-card-sub-title>
      </div>
      
      <bar-chart :barColors="barColors" :barData="bar" :barCategories="barCategories" />
    </div>

    <b-row>
      <b-button
        @click="openForm"
        variant="primary"
        block
        class="mt-3 mx-md-2 mx-0"
        >View recommended toolkits</b-button
      >
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

import WhatDoesThisMean from "../../components/modals/WhatDoesThisMean.vue";
import BarChart from "../../components/my-hub/BarChart.vue";

const $strokeColor1 = "#ededed";
const $strokeColor2 = "#59949c";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardSubTitle,
    VueApexCharts,
    WhatDoesThisMean,
    BarChart,
  },
  props: [
    "data",
    "bar",
    "title",
    "type",
    "subcategory",
    "message",
    "qName",
    "barColors",
    "chartColor",
    "completedOn",
    "professional",
    "questionnaireCategory",
    "barCategories"
  ],
  methods: {
    openForm() {
      this.$router.push("/learn-and-develop");
    },
  },
  created: function () {
    if (this.data.series[0] > this.data.series[1]) {
      this.valueToDisplayInChart = this.data.series[0];
    } else {
      this.valueToDisplayInChart = this.data.series[1];
    }
  },
  computed: {
    reversedMessage: function () {
      return this.valueToDisplayInChart;
    },
  },
  mounted() {
    if(this.$refs.circleChart != undefined) {
      this.$refs.circleChart.updateOptions({
        colors: [$strokeColor1, this.chartColor],
      });
    }
  },
  data() {
    return {
      valueToDisplayInChart: 10,
      circleChart: {
        chartOptions: {
          tooltip: {
            enabled: false,
          },
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
            selection: {
              enabled: false,
            },
          },
          states: {
            hover: {
              filter: {
                type: "none",
              },
            },
            active: {
              filter: {
                type: "none",
              },
            },
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
          },
          legend: { show: false },
          labels: ["of 10", "of 10"],
          stroke: { width: 0 },
          colors: [$strokeColor1, $strokeColor2],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 20,
                    fontSize: "20px",
                    label: "out of 10",
                  },
                  value: {
                    offsetY: -20,
                    fontSize: "20px",
                    label: "out of 10",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    label: "out of 10",
                    fontWeight: 300,
                    offsetY: -10,
                    formatter(value, opts) {
                      let pom = value.globals.series;

                      if (pom[0] == 10 && pom[1] == 0.3) {
                        return 0;
                      }

                      if (pom[0] == 10 || pom[1] == 10) {
                        return 10;
                      }
                      return pom[1];
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {
    $props: {
      handler(val) {
        if (this.$refs.circleChart != undefined) {
          this.$refs.circleChart.updateOptions({
            colors: [$strokeColor1, this.chartColor],
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style>
.apexcharts-datalabel-label {
  font-size: 13px !important;
}

#cir-ch-prev {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.chart-row {
  position: inherit;
  flex: 1;
}
</style>
